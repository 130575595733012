<template>
    <div class="points" v-if="!userIsPoint">
        <v-row style="max-width: 1400px" class="mx-auto">
            <v-col cols="12">
                <v-card align="center" justify="center" class="mx-auto elevation-6">
                    <v-toolbar height="50" color="indigo darken-6 elevation-3" dark flat>
                        <v-toolbar-title class="overline">
                            {{ $t('points.list_of_points', { p1: points.length, p2: usersCount, p3: usersCountOnline }) }}
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn icon @click="onClickUpdate">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-title class="pt-0 pb-2 d-flex">
                        <v-btn outlined small text tile class="mx-2 mt-3 flex-grow-0" @click="closeAll()">Свернуть</v-btn>
                        <v-btn outlined small text tile class="mx-2 mt-3  flex-grow-0" @click="openAll()">Развернуть</v-btn>
                        <v-btn outlined small tile color="success" class="mx-2 mt-3  flex-grow-0" @click="onClickCreate()">Создать</v-btn>

                        <v-spacer></v-spacer>

                        <v-text-field style="width: 340px; flex: initial" v-model="search" append-icon="mdi-magnify" :label="$t('common.search')" single-line hide-details></v-text-field>
                    </v-card-title>

                    <v-data-table
                        :mobile-breakpoint="0"
                        dense
                        no-data-text="Нет данных"
                        no-results-text="Нет данных, попробуйте изменить запрос"
                        :footer-props="{
                            pageText: '{0} - {1} из {2}',
                            itemsPerPageText: 'Показывать на странице',
                            itemsPerPageAllText: 'все',
                            showFirstLastPage: true,
                            itemsPerPageOptions: [25, 50, 100, -1],
                        }"
                        :single-expand="false"
                        show-expand
                        :headers="headers"
                        :search="search"
                        :items="points"
                        item-key="id"
                        :loading="loading"
                        :loading-text="$t('common.loading')"
                        @update:items-per-page="updatePaginate"
                        @update:page="updatePaginate"
                        ref="dTable"
                    >
                        <template v-slot:item="{ item, expand, isExpanded }">
                            <tr @click="ordersDialog || editFlag ? false : expand(!isExpanded)">
                                <td :colspan="headers.length - 4">
                                    <v-btn small icon :data-open="isExpanded">
                                        <v-icon v-if="isExpanded">mdi-chevron-up</v-icon>
                                        <v-icon v-else>mdi-chevron-down</v-icon>
                                    </v-btn>

                                    <v-avatar size="20" class="mx-0" v-if="item.settings.subscribe_enable">
                                        <img alt="subscribe" :src="`${ require('../../assets/images/darall-small.png') }`" />
                                    </v-avatar>
                                    
                                    <b class="ml-1">{{ item.name }}</b> - {{ item.address }}

                                    <v-chip class="mx-2 px-2" style="cursor:pointer" color="blue lighten-2" outlined label x-small>{{ item.users.length }}</v-chip>

                                    <v-btn small icon @click="onClickEdit(item)">
                                        <v-icon>mdi-home-edit-outline</v-icon>
                                    </v-btn>

                                </td>
                                <td>
                                    <span style="width:35px; display: inline-block;">{{ item.ordersToday }}</span>
                                    <v-btn v-if="item.ordersToday" outlined text tile x-small @click="onClickTodayDetails(item)"><v-icon small>mdi-menu</v-icon></v-btn>
                                </td>
                                <td>
                                    <span style="width:35px; display: inline-block;">{{ item.ordersYesterday }}</span>
                                    <v-btn v-if="item.ordersYesterday" outlined text tile x-small @click="onClickYesterdayDetails(item)"><v-icon small>mdi-menu</v-icon></v-btn>
                                </td>
                                <td>
                                    <span style="width:35px; display: inline-block;">{{ item.ordersMonth }}</span>
                                    <v-btn v-if="item.ordersMonth" outlined text tile x-small @click="onClickMonthDetails(item)"><v-icon small>mdi-menu</v-icon></v-btn>
                                </td>
                            </tr>
                        </template>

                        <template v-slot:expanded-item="{ headers, item }">
                            <tr class="d-none">{{headers.length}}</tr>
                            <tr class="expanded-content">
                                <td></td>
                                <td></td>
                                <td>
                                    <p v-for="user in item.users" :key="user.id">
                                        <v-avatar size="24" class="mr-2">
                                            <img alt="point" :src="`${config.api.delivery + 'user_avatar?tid=' + user.tid}`" />
                                        </v-avatar>
                                        <b>{{ user.full_name.length > 30 ? user.full_name.substr(0, 30) + '...' : user.full_name }}</b>
                                    </p>
                                </td>
                                <td>
                                    <p class="py-1" v-for="user in item.users" :key="user.id">
                                        <span class="account-name" v-if="user.name" @click="onClickAccountEdit(user)">{{ user.name }}</span>
                                        <v-btn v-if="!user.name" class="round-icon" outlined text rounded x-small @click="onClickAccountEdit(user)"><v-icon x-small>mdi-plus</v-icon></v-btn>
                                        <!-- <span v-if="!user.name">{{ user.name }}</span> -->
                                    </p>
                                </td>
                                <td>
                                    <p class="py-1" v-for="user in item.users" :key="user.id">
                                        <span :style="{ color: getConnectColor(user.enable) }">{{ getConnectStatus(user.enable) }}</span>
                                    </p>
                                </td>
                                <td>
                                    <p v-for="user in item.users" :key="user.id">
                                        <span class="py-1" style="width:35px; display: inline-block;">{{ user.ordersToday }}</span>
                                        <v-btn v-if="user.ordersToday" outlined text tile x-small @click="onClickTodayDetails(item, user)"><v-icon small>mdi-menu</v-icon></v-btn>
                                    </p>
                                </td>
                                <td>
                                    <p v-for="user in item.users" :key="user.id">
                                        <span class="py-1" style="width:35px; display: inline-block;">{{ user.ordersYesterday }}</span>
                                        <v-btn v-if="user.ordersYesterday" outlined text tile x-small @click="onClickYesterdayDetails(item, user)"><v-icon small>mdi-menu</v-icon></v-btn>
                                    </p>
                                </td>
                                <td>
                                    <p v-for="user in item.users" :key="user.id">
                                        <span class="py-1" style="width:35px; display: inline-block;">{{ user.ordersMonth }}</span>
                                        <v-btn v-if="user.ordersMonth" outlined text tile x-small @click="onClickMonthDetails(item, user)"><v-icon small>mdi-menu</v-icon></v-btn>
                                    </p>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

        <div class="text-center">
            <v-dialog v-model="pointDialog" max-width="800" @click:outside="editFlag = false">
                <v-card class="points-dialog">
                    <v-card-title class="headline grey lighten-2">
                        {{ dialogHeader }}
                    </v-card-title>

                    <v-card-text>
                        <v-form>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" class="py-0">
                                        <v-text-field v-model="pointCopy.name" label="Наименование"></v-text-field>
                                    </v-col>

                                    <v-col cols="8" class="py-0">
                                        <v-text-field v-model="pointPhone" label="Номер телефона"></v-text-field>
                                    </v-col>

                                    <v-col cols="8" class="py-0">
                                        <v-text-field v-model="pointCopy.address" label="Адрес забора" @input="onChangeAddress"></v-text-field>
                                    </v-col>

                                    <v-col cols="4" class="py-0 d-flex">
                                        <v-btn :disabled="addressUpdateFlag" :loading="addressUpdateFlag" elevation="2" class="float-right ma-0 pa-0 mr-2 mt-5" icon small @click="onClickAddressUpdate">
                                            <v-icon>mdi-refresh</v-icon>
                                        </v-btn>
                                        <v-row class="justify-center flex-column">
                                            <span class="ma-0 pa-0 ml-3" :style="{ 'height':'24px', width: '120px', color: addressColor }">{{ addressResult }}</span>
                                            <span class="ma-0 pa-0 ml-3" :style="{ 'height':'24px', width: '120px', color: coordColor }">{{ coordResult }}</span>
                                        </v-row>
                                    </v-col>

                                    <v-col cols="12" class="pb-0">
                                        <p class="my-0">Пользователи Telegram бота</p>
                                    </v-col>

                                    <v-col cols="12" sm="12" md="6">
                                        <v-card class="mx-auto" max-width="500">
                                            <v-toolbar color="teal" dark height="40">
                                                <v-toolbar-title>Пользователи точки</v-toolbar-title>
                                            </v-toolbar>

                                            <v-list dense>
                                                <draggable class="list-group" :list="pointCopy.users" group="people" @change="onChangeUser">
                                                    <v-list-item v-for="user in pointCopy.users" :key="user.id">
                                                        <v-list-item-content class="d-block">
                                                            <v-avatar size="24" class="mr-2">
                                                                <img alt="point" :src="`${config.api.delivery + 'user_avatar?tid=' + user.tid}`" />
                                                            </v-avatar>
                                                            <b>{{ user.full_name.length > 30 ? user.full_name.substr(0, 30) + '...' : user.full_name }}</b>
                                                            <span class="ml-2" :style="{ color: getConnectColor(user.enable) }">{{ getConnectStatus(user.enable).toLowerCase() }}</span>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </draggable>
                                            </v-list>
                                        </v-card>
                                    </v-col>

                                    <v-col cols="12" sm="12" md="6">
                                        <v-card class="mx-auto" max-width="500">
                                            <v-toolbar color="teal" dark height="40">
                                                <v-toolbar-title>Нераспределенные пользователи</v-toolbar-title>
                                            </v-toolbar>

                                            <v-list dense>
                                                <draggable class="list-group" :list="unallocatedUsersCopy" group="people" @change="onChangeUser">
                                                    <v-list-item v-for="user in unallocatedUsersCopy" :key="user.id">
                                                        <v-list-item-content class="d-block">
                                                            <v-avatar size="24" class="mr-2">
                                                                <img alt="point" :src="`${config.api.delivery + 'user_avatar?tid=' + user.tid}`" />
                                                            </v-avatar>
                                                            <b>{{ user.full_name.length > 30 ? user.full_name.substr(0, 30) + '...' : user.full_name }}</b>
                                                            <span class="ml-2" :style="{ color: getConnectColor(user.enable) }">{{ getConnectStatus(user.enable).toLowerCase() }}</span>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </draggable>
                                            </v-list>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn class="mx-2" color="success" dark @click="onClickSave">Сохранить</v-btn>
                        <v-btn class="mx-2" color="error" text @click="onClickDelete">Удалить</v-btn>
                        <v-btn
                            class="mx-2"
                            color="primary"
                            text
                            @click="
                                pointDialog = false
                                editFlag = false
                            "
                            >Закрыть</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="ordersDialog" width="720" scrollable>
                <v-card :loading="loadingOrder">
                    <template slot="progress">
                        <v-progress-linear indeterminate absolute top color="deep-purple accent-4"></v-progress-linear>
                    </template>

                    <v-card-title class="headline grey lighten-2">
                        {{ dialogHeader }}
                    </v-card-title>

                    <v-card-text class="py-4">
                        <div class="text-center">
                            <v-pagination v-model="page" :length="Math.ceil(orders.length / perPage)"></v-pagination>
                        </div>
                        <span v-if="loadingOrder && !visibleOrders.length">{{ $t('common.loading') }}</span>
                        <div v-for="(order, i) of visibleOrders" :key="order.id" class="d-flex">
                            {{ ((page-1) * perPage + i + 1) }}.
                            <v-menu open-delay="750" open-on-hover :close-on-content-click="false" bottom offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on" class="item-order d-inline-block mx-3" :style="orderStatusStyle(order)">{{ order.id }}</span>
                                </template>

                                <v-card class="mx-auto" min-width="120" max-width="400" outlined>
                                    <div class="order-card" v-html="orderContent(order)"></div>
                                </v-card>
                            </v-menu>
                            <span v-html="orderListItem(order)"></span>
                            <br />
                        </div>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="mx-2" color="primary" dark @click="updateDetails">Обновить</v-btn>
                        <v-btn class="mx-2" color="primary" text @click="ordersDialog = false">Закрыть</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="accountDialog" width="500">
                <v-card :loading="loadingAccount">
                    <template slot="progress">
                        <v-progress-linear indeterminate absolute top color="deep-purple accent-4"></v-progress-linear>
                    </template>

                    <v-card-title class="headline grey lighten-2">
                        {{ dialogHeader }}
                    </v-card-title>

                    <v-card-text class="py-4">
                        <v-text-field placeholder="" hide-details name="accountname" type="text" label="Логин" v-model="name_value" prepend-icon="mdi-account" @click:append="pass_show = !pass_show"></v-text-field>
                        <v-text-field placeholder="" hide-details name="accountpass" :append-icon="pass_show ? 'mdi-eye' : 'mdi-eye-off'" :type="pass_show ? 'text' : 'password'" label="Пароль" v-model="pass_value" prepend-icon="mdi-key" @click:append="pass_show = !pass_show"></v-text-field>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="mx-2" color="success" dark @click="onClickAccountSave">Сохранить</v-btn>
                        <v-btn v-if="accountDeleteShow" class="mx-2" color="error" text @click="onClickAccountDelete">Удалить</v-btn>
                        <v-btn class="mx-2" color="primary" text @click="accountDialog = false">Закрыть</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>

        <span class="pb-16 d-block" />

    </div>
</template>

<script>
    /* eslint-disable */
    
    import config from '../../config'
    import draggable from 'vuedraggable'
    import moment from 'moment-timezone'

    moment.locale('ru')

    import DateTime from '../../mixins/DateTime.js'
    import Status from '../../mixins/Status.js'
    import Orders from '../../mixins/Orders.js'

    export default {
        mixins: [DateTime, Status, Orders],
        data() {
            return {
                config,
                loading: false,
                loadingOrder: false,
                search: '',
                pointPhone: '',
                pointCopy: {},
                unallocatedUsers: {},
                unallocatedUsersCopy: {},
                points: [],
                point_users: [],
                usersCount: 0,
                usersCountOnline: 0,
                pointDialog: false,
                ordersDialog: false,
                dialogHeader: '',
                dialogContent: '',
                headers: [
                    {
                        text: 'Название и адрес',
                        align: ' th-first-item',
                        value: 'name',
                    },
                    {
                        align: '1 d-none',
                        value: 'name',
                        sortable: false,
                    },
                    {
                        align: '1 d-none',
                        value: 'address',
                        sortable: false,
                    },
                    {
                        text: 'Имя в Telegram',
                        value: 'full_name',
                        sortable: false,
                    },
                    {
                        text: 'Аккаунт',
                        value: 'name',
                        sortable: true,
                    },
                    {
                        text: 'Подключение',
                        value: 'enable',
                        sortable: false,
                    },
                    {
                        text: 'Заказы сегодня',
                        value: 'ordersToday',
                    },
                    {
                        text: 'Заказы вчера',
                        value: 'ordersYesterday',
                    },
                    {
                        text: 'Заказы за 30 дней',
                        value: 'ordersMonth',
                    },
                ],
                editFlag: false,
                addressUpdateFlag: false,
                addressResult: '',
                addressColor: '',
                coordResult: '',
                coordColor: '',
                timerAddressUpdate: null,
                createFlag: false,
                detailsPoint: 0,
                detailsUser: 0,
                detailsDate1: '',
                detailsDate2: '',
                orders: [],
                page: 1,
                perPage: config.paginateItemsCount,
                userIsPoint: false,
                accountDialog: false,
                pass_show: false,
                name_value: '',
                pass_value: '',
                currentUser: null,
                loadingAccount: false,
                accountDeleteShow: false,
            }
        },
        components: {
            draggable,
        },
        mounted() {
            console.log('start!')

            this.userIsPoint = this.$store.state.user.role != config.user.role.operator
            if (!this.userIsPoint) {
                this.updateData()
            }

            // setTimeout(() => {
            //     this.points[0].name = '------'
            // }, 5000)

        },

        beforeDestroy() {},

        destroyed() {},
        watch: {},
        computed: {
            visibleOrders() {
                return this.orders.slice((this.page - 1) * this.perPage, this.page * this.perPage)
            },
        },
        methods: {
            updatePaginate() {},
            onClickAccountEdit(user) {
                this.currentUser = user
                if (user.name) {
                    this.dialogHeader = 'Доступ - ' + this.currentUser.full_name
                    this.name_value = this.currentUser.name
                    this.pass_value = this.currentUser.pass
                    this.accountDeleteShow = true
                } else {
                    this.dialogHeader = 'Новый доступ - ' + this.currentUser.full_name
                    this.name_value = ''
                    this.pass_value = ''
                    this.accountDeleteShow = false
                }
                this.accountDialog = true
            },
            onClickAccountSave() {
                if (this.name_value == '') {
                    this.$notify.toast('Логин нужно заполнить.', {color:'error', timeout: 4000})
                } else if (this.pass_value == '') {
                    this.$notify.toast('Пароль нужно заполнить.', {color:'error', timeout: 4000})
                } else {
                    this.name_old = this.currentUser.name
                    this.pass_old = this.currentUser.pass
                    this.currentUser.name = this.name_value.toLowerCase()
                    this.currentUser.pass = this.pass_value.toLowerCase()
                    this.userSave()
                }
            },
            onClickAccountDelete() {
                this.name_old = this.currentUser.name
                this.pass_old = this.currentUser.pass
                this.currentUser.name = null
                this.currentUser.pass = null
                this.userSave()
            },
            userSave() {
                this.loadingAccount = true
                this.$store
                    .dispatch('user_set', this.currentUser)
                    .then(res => {
                        if (res.data.success) {
                            this.$notify.toast('Аккаунт изменен успешно.', {color:'success', timeout: 4000})
                        } else {
                            this.$notify.toast('Ошибка сохранения аккаунта.', {color:'error', timeout: 4000})
                            this.currentUser.name = this.name_old
                            this.currentUser.pass = this.pass_old
                        }
                        this.loadingAccount = false
                        this.accountDialog = false
                    })
                    .catch(err => {
                        console.log('users_set error', err)
                        this.$notify.toast('Ошибка сохранения аккаунта. ' + err, {color:'error', timeout: 4000})
                        this.currentUser.name = this.name_old
                        this.currentUser.pass = this.pass_old
                        this.loadingAccount = false
                        this.accountDialog = false
                    })
            },
            onClickTodayDetails(point, user) {
                this.page = 1
                this.orders = []
                const user_name = user ? ', ' + user.full_name : ''
                const count = user ? user.ordersToday : point.ordersToday
                this.dialogHeader = `${point.name}${user_name} - заказы сегодня (${count})`
                this.detailsPoint = !user ? point.id : 0
                this.detailsUser = user ? user.tid : 0
                this.detailsDate1 = this.getDate1String()
                this.detailsDate2 = this.getDate2String()
                this.dialogContent = this.$t('common.loading')
                this.updateDetails(user)
                this.ordersDialog = true
            },
            onClickYesterdayDetails(point, user) {
                this.page = 1
                this.orders = []
                const user_name = user ? ', ' + user.full_name : ''
                const count = user ? user.ordersYesterday : point.ordersYesterday
                this.dialogHeader = `${point.name}${user_name} - заказы вчера (${count})`
                this.detailsPoint = !user ? point.id : 0
                this.detailsUser = user ? user.tid : 0
                this.detailsDate1 = this.getDate1String(-1)
                this.detailsDate2 = this.getDate2String(-1)
                this.dialogContent = this.$t('common.loading')
                this.updateDetails(user)
                this.ordersDialog = true
            },
            onClickMonthDetails(point, user) {
                this.page = 1
                this.orders = []
                const user_name = user ? ', ' + user.full_name : ''
                const count = user ? user.ordersMonth : point.ordersMonth
                this.dialogHeader = `${point.name}${user_name} - заказы за 30 дней (${count})`
                this.detailsPoint = !user ? point.id : 0
                this.detailsUser = user ? user.tid : 0
                this.detailsDate1 = this.getDate1String(-30)
                this.detailsDate2 = this.getDate2String()
                this.dialogContent = this.$t('common.loading')
                this.updateDetails(user)
                this.ordersDialog = true
            },
            updateDetails() {
                this.loadingOrder = true

                this.$store
                    .dispatch('orders_date', {
                        point_id: this.detailsPoint,
                        point_tid: this.detailsUser,
                        date1: this.detailsDate1,
                        date2: this.detailsDate2,
                        deliveryTime: true,
                    })
                    .then(res => {
                        this.dialogContent = ''
                        if (res.data.success) {
                            this.orders = res.data.orders
                            // this.orders = res.data.orders.filter(o => o.status != config.order.status.forming)
                            // console.log('orders', this.orders)
                        } else {
                            this.dialogContent = this.$t('common.loading_error', { p1: 1 })
                        }
                        this.loadingOrder = false
                    })
                    .catch(err => {
                        this.loadingOrder = false
                        console.log('point_orders_get error', err)
                        this.dialogContent = this.$t('common.loading_error', { p1: 2 })
                    })
            },
            onChangeUser() {},
            onClickSave() {
                this.loading = true
                this.pointDialog = false
                this.editFlag = false

                this.pointCopy.settings.phone = this.pointPhone

                this.$store
                    .dispatch('point_set', this.pointCopy)
                    .then(res => {
                        if (res.data.success) {
                            console.log('point_set success', res.data)
                            if (res.data.point.id) {
                                this.pointCopy.id = res.data.point.id
                                for (let user of this.pointCopy.users) {
                                    user.point_id = res.data.point.id
                                }
                            }

                            this.currentPoint.id = this.pointCopy.id
                            this.currentPoint.name = this.pointCopy.name
                            this.currentPoint.address = this.pointCopy.address
                            this.currentPoint.coordinates = this.pointCopy.coordinates
                            this.currentPoint.settings.phone = this.pointPhone
                            this.currentPoint.users = this.pointCopy.users.sort(this.sortUsers)
                            if (this.createFlag) {
                                this.createFlag = false
                                this.points.push(this.currentPoint)
                                this.points = this.points.sort(this.sortPoints)
                            }

                            this.unallocatedUsers = this.unallocatedUsersCopy.sort(this.sortUsers)
                            let pointsSave = []
                            for (let user of this.unallocatedUsers) {
                                let puser = this.point_users.find(puser => puser.id == user.id)
                                if (puser) {
                                    puser.point_id = 0
                                    pointsSave.push(puser)
                                }
                            }
                            console.log('this.pointCopy', this.pointCopy)
                            for (let user of this.pointCopy.users) {
                                // let puser = this.point_users.find(puser => puser.id == user.id)
                                // if (puser) {
                                    user.point_id = this.pointCopy.id
                                    user.role = config.user.role.point
                                    pointsSave.push(user)
                                // }
                            }
                            console.log('pointsSave', pointsSave)

                            if (pointsSave.length) {
                                this.$store
                                    .dispatch('users_set', pointsSave)
                                    .then(res => {
                                        if (res.data.success) {
                                            console.log('users_set success')
                                        } else {
                                            console.log('users_set not success')
                                        }
                                        this.loading = false
                                    })
                                    .catch(err => {
                                        console.log('users_set error', err)
                                        this.loading = false
                                    })
                            } else {
                                this.loading = false
                            }
                        } else {
                            console.log('point_set not success')
                        }
                    })
                    .catch(err => {
                        console.log('point_set error', err)
                        this.loading = false
                    })
            },
            onClickDelete() {
                this.loading = true
                this.pointDialog = false
                this.editFlag = false
                let unallocated = []
                for (let user of this.pointCopy.users) {
                    let puser = this.point_users.find(puser => puser.id == user.id)
                    if (puser) {
                        puser.point_id = 0
                        this.unallocatedUsers.push(JSON.parse(JSON.stringify(puser)))
                        unallocated.push(puser)
                    }
                }
                console.log('unallocatedUsers', this.unallocatedUsers)
                console.log('unallocated', unallocated)
                this.$store
                    .dispatch('point_delete', this.pointCopy.id)
                    .then(res => {
                        if (res.data.success) {
                            console.log('point_delete success')
                            if (unallocated.length) {
                                this.$store
                                    .dispatch('users_set', unallocated)
                                    .then(res => {
                                        if (res.data.success) {
                                            console.log('users_set success')
                                        } else {
                                            console.log('users_set not success')
                                        }
                                        this.loading = false
                                    })
                                    .catch(err => {
                                        this.loading = false
                                        console.log('users_set error', err)
                                    })
                            } else {
                                this.loading = false
                            }
                        } else {
                            this.loading = false
                            console.log('point_delete not success')
                        }
                    })
                    .catch(err => {
                        this.loading = false
                        console.log('point_delete error', err)
                    })
                this.points = this.points.filter(point => point.id != this.pointCopy.id)
            },
            onClickEdit(point) {
                this.currentPoint = point

                this.unallocatedUsers = this.unallocatedUsers.sort(this.sortUsers)

                this.unallocatedUsersCopy = JSON.parse(JSON.stringify(this.unallocatedUsers))
                this.pointCopy = JSON.parse(JSON.stringify(point))
                this.pointPhone = this.currentPoint.settings.phone

                this.editFlag = true
                this.dialogHeader = `Точка ${point.name} - ${point.id}`
                this.pointDialog = true
                // this.addressResult = ''
                // this.onChangeAddress()
                const coordinates = JSON.parse(this.pointCopy.coordinates)
                if (coordinates && coordinates[0] && coordinates[1]) {
                    this.coordResult = 'Координаты ок'
                    this.coordColor = 'green'
                } else {
                    this.coordResult = 'Без координат'
                    this.coordColor = 'red'
                }
                this.addressResult = ''
            },
            onClickCreate() {
                this.currentPoint = {
                    id: null,
                    name: '',
                    address: '',
                    status: 0,
                    operator_tid: this.$store.state.user.tid,
                    users: [],
                    ordersToday: 0,
                    ordersYesterday: 0,
                    ordersMonth: 0,
                    settings: {},
                }
                this.addressResult = 'Введите адрес'
                this.addressColor = 'grey'
                this.coordResult = ''
                this.coordColor = 'grey'

                this.unallocatedUsers = this.unallocatedUsers.sort(this.sortUsers)

                this.unallocatedUsersCopy = JSON.parse(JSON.stringify(this.unallocatedUsers))
                this.pointCopy = JSON.parse(JSON.stringify(this.currentPoint))
                this.pointPhone = this.pointCopy.settings.phone

                this.createFlag = true
                this.dialogHeader = `Новая точка`
                this.pointDialog = true
            },
            addressUpdate() {
                this.addressUpdateFlag = true
                // тут надо разбираться, не лажа ли написана
                // let point_id = 0
                // const point = this.points.find(point => point.tid == this.pointCopy.tid)
                // if (point) {
                //     point_id = point.point_id
                // }
                // console.log('point_id,', point_id)
                // console.log('this.pointCopy', this.pointCopy)

                this.$store
                    .dispatch('point_address', { point_name: this.pointCopy.name, address: this.pointCopy.address })
                    .then(res => {
                        console.log('res,', res)

                        if (res.data.success) {
                            this.addressResult = 'Адрес найден'
                            this.addressColor = 'green'
                        } else {
                            this.addressResult = 'Адрес не найден'
                            this.addressColor = 'red'
                        }
                        if (res.data.coordinates && res.data.coordinates[0] && res.data.coordinates[1]) {
                            this.coordResult = 'Координаты ок'
                            this.coordColor = 'green'
                            this.pointCopy.coordinates = JSON.stringify(res.data.coordinates)
                        } else {
                            this.coordResult = 'Без координат'
                            this.coordColor = 'red'
                            this.pointCopy.coordinates = null
                        }

                        this.addressUpdateFlag = false
                    })
                    .catch(err => {
                        console.log(err)
                        this.addressResult = 'Адрес не найден'
                        this.addressColor = 'red'
                        this.coordResult = 'Без координат'
                        this.coordColor = 'red'
                        this.pointCopy.coordinates = null
                        this.addressUpdateFlag = false
                    })
            },
            onClickAddressUpdate() {
                if (this.timerAddressUpdate) {
                    clearTimeout(this.timerAddressUpdate)
                    this.timerAddressUpdate = null
                }
                this.addressUpdateFlag = true
                this.addressResult = 'Поиск...'
                this.addressColor = 'grey'
                this.coordResult = ''
                this.coordColor = 'grey'
                this.addressUpdate()
            },
            onChangeAddress() {
                // тут так должно быть!
                this.addressUpdateFlag = true
                this.addressResult = 'Поиск...'
                this.addressColor = 'grey'
                this.coordResult = ''
                this.coordColor = 'grey'
                if (this.timerAddressUpdate) {
                    clearTimeout(this.timerAddressUpdate)
                    this.timerAddressUpdate = null
                }
                this.timerAddressUpdate = setTimeout(() => {
                    this.timerAddressUpdate = null
                    this.addressUpdateFlag = true
                    this.addressResult = 'Поиск...'
                    this.addressColor = 'grey'
                    this.coordResult = ''
                    this.coordColor = 'grey'
                    this.addressUpdate()
                }, 2000)
            },
            onClickUpdate() {
                this.updateData()
            },
            getConnectColor(status) {
                return status == 1 ? '#4CAF50' : '#F44336'
            },
            getConnectStatus(status) {
                return status == 1 ? 'Подключен' : 'Отключен'
            },
            openAll() {
                for (const point of this.points) {
                    this.$set(this.$refs.dTable.expansion, point.id, true)
                }
            },
            closeAll() {
                for (const point of this.points) {
                    this.$set(this.$refs.dTable.expansion, point.id, false)
                }
            },
            sortPoints(a, b) {
                if (a.name > b.name) {
                    return 1
                }
                if (a.name < b.name) {
                    return -1
                }
                if (a.address > b.address) {
                    return 1
                }
                if (a.address < b.address) {
                    return -1
                }
                return 0
            },
            sortUsers(a, b) {
                if (a.full_name > b.full_name) {
                    return 1
                }
                if (a.full_name < b.full_name) {
                    return -1
                }
                return 0
            },
            updateData() {
                this.loading = true
                this.$store
                    .dispatch('points_get')
                    .then(res => {
                        if (res.data.success) {
                            let points = res.data.points.sort(this.sortPoints)

                            console.log('points', points)

                            this.$store
                                .dispatch('point_users')
                                .then(res => {
                                    if (res.data.success) {
                                        this.usersCount = 0
                                        this.usersCountOnline = 0

                                        this.point_users = res.data.users.filter(u => u.point_id).sort(this.sortUsers)

                                        this.points = points.map(p => {
                                            p.users = []
                                            for (const user of this.point_users) {
                                                if (p.id == user.point_id) {
                                                    p.users.push(user)
                                                }
                                            }
                                            return p
                                        })

                                        this.usersCount = this.point_users.length
                                        this.usersCountOnline = this.point_users.filter(u => u.enable).length

                                        this.$store
                                            .dispatch('users_get')
                                            .then(res => {
                                                if (res.data.success) {
                                                    this.unallocatedUsers = res.data.users.filter(u => !u.point_id)
                                                    console.log('unallocatedUsers', this.unallocatedUsers)
                                                }
                                                this.loading = false
                                            })
                                            .catch(err => {
                                                this.loading = false
                                                console.log(err)
                                            })
                                    } else {
                                        this.loading = false
                                    }
                                })
                                .catch(err => {
                                    this.loading = false
                                    console.log(err)
                                })
                        } else {
                            this.loading = false
                        }
                    })
                    .catch(err => {
                        this.loading = false
                        console.log(err)
                    })
            },
            orderListItem(order) {
                return ` <b>${order.name}</b> - ${moment(order.date).format('D MMM HH:mm')} - <span style="color: ${this.orderStatusColorHtml(order.status)};">${this.orderStatusStr(order.status)}${order.date_complete ? ` (${moment(order.date_complete).format('D MMM HH:mm')})` : ''}</span><br>`
            },
        },
    }
</script>

<style lang="stylus">

    .points
        height 100%
        padding-top 10px !important

        .v-card
            cursor default

        .v-toolbar
            border-bottom-left-radius 0 !important
            border-bottom-right-radius 0 !important

        .v-data-table
            padding 0 16px
            .v-data-table__wrapper > table > thead > tr
                th
                    padding 0 8px
                    white-space nowrap
            .v-data-table__wrapper > table > tbody > tr
                cursor pointer
                .v-data-table__expanded__row:hover
                    background #e4e4e4 !important
                    cursor pointer !important

                td
                    white-space nowrap
                    font-size 0.75rem
                    padding 0 8px
                    p
                        margin-top 6px
                        margin-bottom 6px
                        height 22px

        .expanded-content
            box-shadow inset 0px 4px 8px -5px rgba(50, 50, 50, 0.75), inset 0px -4px 8px -5px rgba(50, 50, 50, 0.75)
            background none !important
            &:hover
                cursor default !important

        .th-first-item
            width 30%

        b
            font-weight 500

    .points-dialog
        b
            font-weight 500
        .v-list
            overflow-y auto
            height 132px
            .list-group
                height 100%
                .v-list-item
                    cursor pointer
                    &:hover
                        background-color beige
                .sortable-ghost
                    background-color beige

    .round-icon
        min-width 20px !important
        width 20px
        height 20px

    .account-name
        cursor pointer
        &:hover
            font-weight 500

</style>
